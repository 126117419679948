import { match_ } from "../../../utils/match_";
import NotValidatedView from "./NotValidatedView";
import RempeNotValidatedView from "./RempeNotValidatedView";
import UnknownErrorView from "./UnknownErrorView";
import ValidationFailedView from "./ValidationFailedView";

export const SetupErrorCode = {
  Unauthorized: "unauthorized",
  ProfessionalNotFound: "professional_not_found",
  PatientFieldsError: "patient_fields_error",
  GeneralError: "general_error",
} as const;

export interface ErrorViewProps {
  errorCode: (typeof SetupErrorCode)[keyof typeof SetupErrorCode];
}

const ErrorView = ({ errorCode }: ErrorViewProps) => {
  return (
    <main className="h-screen px-2 py-1">
      {match_({
        value: errorCode,
        branches: [
          {
            pattern: SetupErrorCode.Unauthorized,
            result: () => <NotValidatedView />,
          },
          {
            pattern: SetupErrorCode.ProfessionalNotFound,
            result: () => <RempeNotValidatedView />,
          },
          {
            pattern: SetupErrorCode.PatientFieldsError,
            result: () => <ValidationFailedView />,
          },
          {
            pattern: SetupErrorCode.GeneralError,
            result: () => <UnknownErrorView />,
          },
        ],
        fallback: () => <UnknownErrorView />,
      })}
    </main>
  );
};

export default ErrorView;
