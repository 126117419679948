import AlertIcon from "../../../assets/icons/AlertIcon";
import { cn } from "../../../utils/cn";

export const Title = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => (
  <h1 className={cn("text-primary font-medium text-2xl", className)}>
    {children}
  </h1>
);

export const Paragraph = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => <p className={cn("text-primary-darker", className)}>{children}</p>;

export const ListItem = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <li className={cn("text-[#6D658B] list-disc ml-4", className)}>{children}</li>
);

const fields = ["Nombre", "Apellido", "Edad", "DNI"];

const ValidationFailedView = () => {
  return (
    <div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
      <div className="flex-1 flex flex-col justify-center">
        <div className="container mx-auto max-w-[440px] space-y-6">
          <AlertIcon className="mx-auto w-11 h-11 text-negative" />
          <Title className="mt-1">
            No se ha podido añadir una receta al paciente, ya que no tiene los
            datos necesarios.
          </Title>

          <div className="space-y-6">
            <Paragraph>
              Hemos detectado que algunos de los siguientes datos del paciente
              son incorrectos:
            </Paragraph>
            <ul>
              {fields.map((field) => (
                <ListItem key={field}>{field}</ListItem>
              ))}
            </ul>
            <Paragraph>
              Por favor, comuníque al paciente que revise y corrija la
              información correspondiente para poder proceder correctamente.
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationFailedView;
