import { MEDIQUO_PRO_SUPPORT_EMAIL } from "../../../config/constants";
import { Paragraph, Title } from "./ValidationFailedView";
import AlertIcon from "../../../assets/icons/AlertIcon";

const UnknownErrorView = () => {
  return (
    <div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
      <div className="flex-1 flex flex-col justify-center">
        <div className="container mx-auto max-w-[440px] space-y-6">
          <AlertIcon className="mx-auto w-11 h-11 text-negative" />
          <Title className="mt-1 text-center">Algo ha ido mal...</Title>

          <div className="space-y-6">
            <Paragraph>
              Lo sentimos, ha ocurrido un error inesperado. Por favor contáctate
              con tu asesor mediQuo a través del correo{" "}
              <strong>{MEDIQUO_PRO_SUPPORT_EMAIL}</strong>
            </Paragraph>
            <Paragraph>
              También puedes comunicarte con tu asesor a través de tus chats.
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnknownErrorView;
