interface Branch<TInput, TOutput> {
  pattern: TInput;
  result: TOutput | (() => TOutput);
}

export interface MatchProps<TInput, TOutput> {
  value: TInput;
  branches: Array<Branch<TInput, TOutput>>;
  fallback?: TOutput | (() => TOutput);
}

/**
 * @description Pattern matching syntax proposal implemented as a function.
 * It only uses basic `===` equality for matching
 *
 * @link https://github.com/tc39/proposal-pattern-matching
 */
export function match_<TInput, TOutput>({
  value,
  branches,
  fallback,
}: MatchProps<TInput, TOutput>): TOutput {
  for (const branch of branches) {
    if (branch.pattern === value) {
      return typeof branch.result === "function"
        ? (branch.result as Function)()
        : branch.result;
    }
  }

  if (fallback) {
    return typeof fallback === "function" ? (fallback as Function)() : fallback;
  }

  throw new Error("No match found");
}
